import { createSlice } from '@reduxjs/toolkit'
// import { $addOrder,$checkWord,$order } from "../../api"
// import { Toast } from 'antd-mobile'


interface initialStateType {
    isBigRedPacket:boolean
}
const initialState:initialStateType ={
    isBigRedPacket:false
}

const redPacketReducer = createSlice({
    name:'redPacketReducer',
    initialState,
    reducers:{
        openBigRedPacketMethods(state,action){
            state.isBigRedPacket = action.payload
        }
    }
})



export const { openBigRedPacketMethods } = redPacketReducer.actions




export default redPacketReducer.reducer