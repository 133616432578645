function getUrlQuery(url:string,name:string) {
    const newUrl = new URL(url)
  return newUrl.searchParams.get(name)
}

// function getUrlQuery(url:string) {
//     let str = url.substr(url.indexOf('?') + 1)
//     let json:{[jsonType:string]:any} = new Object()
//     const arr = str.split('&')
//     for(let i = 0; i < arr.length; i++) {
//       let item = arr[i].split('=')
//       json[item[0]] = item[1]
//     }
//     return json
//   }


export default getUrlQuery